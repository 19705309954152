import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { useGraphQL } from '@/data';
import { GatewaysRead } from '@/data/gateway.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Order, QueryGatewaysReadArgs } from '@/types/schema';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { Box, MenuItem, MenuList, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { isEmpty, isString, uniq } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type QBTaxType = {
	id: string,
	name: string,
	prices: any,
	value: number
};

export function QBTaxesButton( {
	tax,
	setTax,
}: {
	tax: QBTaxType,
	setTax: ( tax: QBTaxType ) => void
} ) {
	const { showMenu } = useMenu();
	
	const { data } = useGraphQL<QueryGatewaysReadArgs>( {
		query    : GatewaysRead,
		queryKey : [ 'gatewaysRead' ],
		variables: { options: { filter: { external: 'QUICKBOOKS' } } },
	} );
	
	const gateway = data?.gatewaysRead?.items?.[ 0 ];
	
	const { data: taxCodes, isLoading } = useQuery(
		[ 'taxRates', gateway?.id ],
		() => axios.post( `/api/quickbooks/${gateway.id}/getTaxRates` ), {
			enabled: !!gateway,
			select : ( { data } ) => getFormattedQBTaxes( data ),
		} );
	
	if ( isEmpty( gateway ) ) return null;
	
	return (
		<AsyncLoadingButton
			sx={{ my: 1 }}
			variant='outlined'
			loading={isLoading}
			disabled={taxCodes?.length < 1}
			color='primary'
			startIcon={<FilterListIcon/>}
			onClick={( e ) => showMenu( ( { closeMenu } ) => (
				<MenuList>
					{uniq( taxCodes ).map( ( item, index ) => (
						<MenuItem
							key={index}
							selected={item.name === tax?.name}
							onClick={() => {
								item.name === 'No Tax' ? setTax( null ) : setTax( item );
								closeMenu();
							}}>
							{`${item.name} ${item.name !== 'No Tax' ? `(${item.value}%)` : ''}`}
						</MenuItem>
					) )}
				</MenuList>
			), e.currentTarget )}>
			{tax?.name || 'No Tax'}
		</AsyncLoadingButton>
	);
}

export const getFormattedQBTaxes = ( data ) => [
	{ id: null, name: 'No Tax', prices: [], value: 0 },
	...data.taxCode?.filter( ( tc ) => !isNaN( tc.Id ) ).map( ( tc ) => ( {
		id    : tc.Id,
		name  : tc.Name,
		prices: tc?.SalesTaxRateList?.TaxRateDetail?.reduce( ( pricesArr, trd ) => {
			const taxRate = data.taxRate?.find( ( tr ) => tr.Id === trd.TaxRateRef.value );
			if ( taxRate ) {
				pricesArr = [
					...pricesArr, {
						id       : taxRate.Id,
						value    : taxRate.RateValue,
						isPercent: true,
						quantity : 1,
						name     : taxRate.Name,
						metadata : { taxCodeRef: tc.Id },
					} ];
			}
			return pricesArr;
		}, [] ),
		value : tc?.SalesTaxRateList?.TaxRateDetail?.reduce( ( sumTax, trd ) => {
			sumTax = sumTax + data.taxRate?.find( ( tr ) => tr.Id === trd.TaxRateRef.value )?.RateValue;
			return sumTax;
		}, 0 ),
	} ) ) ];

export default function QbInvoiceTax( { invoice, companyId, syncPayments, noSync, getQBTax }: {
	invoice: Order,
	companyId: string,
	syncPayments: boolean,
	noSync?: boolean,
	getQBTax?: ( tax ) => void
} ) {
	const { staff } = useUserInfo();
	const [ tax, setTax ] = useState( null );
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	
	return (
		<ResponsiveModalContainer
			title={t( 'common:select-tax' )}
			saveButtonText={tax?.name === 'No Tax' || !tax
				? t( 'common:select-tax-btn-two' )
				: t( 'common:select-tax-btn-three' )}
			onSave={async () => {
				getQBTax?.( tax );
				if ( !noSync ) {
					try {
						enqueueSnackbar( t( 'common:qb-sync-inprogress' ), { variant: 'info' } );
						await axios.post( '/api/processor/manageQB/postInvoice', {
							id     : invoice.id,
							company: companyId,
							prices : tax?.prices,
							syncPayments,
							staffId: staff?.id,
						} );
						enqueueSnackbar( t( 'common:qb-sync-success' ), { variant: 'success' } );
					} catch ( e ) {
						const cloverErrors = e?.response.data?.cloverErrors;
						if ( cloverErrors ) {
							throw isString( cloverErrors )
								? cloverErrors
								: cloverErrors?.error?.message || cloverErrors?.message || t( 'common:qb-sync-error' );
						} else {
							enqueueSnackbar( t( 'common:qb-sync-conflict' ), { variant: 'default' } );
						}
						
					}
				}
			}}>
			<Box>
				<Typography>
					{t( 'common:multi-tax-des' )}
				</Typography>
				<QBTaxesButton tax={tax} setTax={setTax}/>
			</Box>
		</ResponsiveModalContainer>
	);
}

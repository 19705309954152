import EmailButton from '@/components/emailButton';
import OrderProductsAndTotal from '@/components/orderPoductsAndTotalComponent';
import { isEstimateType, isInvoiceType } from '@/helpers/useIsEstimateType';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import type { Order } from '@/types/schema';
import { urlSearchParams } from '@/utils/urlSearchParams';
import { Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import { isEmpty, toLower } from 'lodash-es';
import React, { Fragment } from 'react';

export default function InvoiceOrderEstimateEmailView( {
	data,
	emailView,
	timezone,
}: {
	data: Order,
	emailView?: boolean,
	timezone?: string
} ) {
	const { isCloverDevice } = useGetDeviceInfo();
	const theme = useTheme();
	const dataColor = data.metadata?.documentLayout?.color || data.company.metadata?.documentLayout?.color;
	const themeBasedLayoutColor = !isEmpty( dataColor?.light ) ? dataColor?.light?.slice( 1 ) : undefined;
	const payableCommerce = data.companyLocation?.gateway?.external === 'CLOVER' && data.externalId && !data.metadata?.disablePayment && !isEmpty( data.lineItems ) && ![ 'PAID',
	                                                                                                                                                                      'CANCELLED',
	                                                                                                                                                                      'ACCOUNT' ].includes( data.status );
	
	return (
		<Fragment>
			<OrderProductsAndTotal data={data} timezone={timezone}/>
			{!isCloverDevice && (
				<EmailButton
					target='_blank'
					href={`${process.env.NEXT_PUBLIC_SITE_URL}/p/${data.id}/${isEstimateType( data.type )
						? 'estimate'
						: isInvoiceType( data.type ) ? 'invoice' : `${toLower( data.type )}`}`}>
					{payableCommerce ? 'Pay Now' : 'View Details'}
				</EmailButton>
			)}
			<Table>
				<TableBody>
					<TableRow>
						<TableCell sx={{ border: '0 !important', padding: '5px !important' }}/>
					</TableRow>
				</TableBody>
			</Table>
			{data.type === 'INVOICE' && data.metadata?.hideClientLink && data.client && (
				<EmailButton
					variant='outlined'
					target='_blank'
					href={`${process.env.NEXT_PUBLIC_SITE_URL}/client/${toLower( data.type )}s?s=${encodeURIComponent( Buffer.from( JSON.stringify( {
						filters: [ data.client && {
							id   : 'client.id',
							value: {
								$eq : data.client.id,
								temp: { checked: true },
							},
						}, {
							id   : 'status',
							value: {
								$in : [ 'SENT', 'VIEWED', 'PARTIALLY_PAID', 'OPEN', 'STANDING' ],
								temp: { checked: true },
							},
						} ].filter( Boolean ),
					} ) ).toString( 'base64' ) )}`}>
					{`Open ${toLower( data.type )}s`}
				</EmailButton>
			)}
			<Table>
				<TableBody>
					<TableRow>
						<TableCell sx={{ border: '0 !important', padding: '5px !important' }}/>
					</TableRow>
				</TableBody>
			</Table>
			{!isCloverDevice && (
				<EmailButton
					variant='text'
					target='_blank'
					emailView={emailView}
					href={`${process.env.NEXT_PUBLIC_SITE_URL}/api/preview/pdf/${data.id}/${isEstimateType( data.type )
						? 'estimate'
						: isInvoiceType( data.type )
							? 'invoice'
							: `${toLower( data.type )}`}?${urlSearchParams( {
						timezone,
						themeBasedLayoutColor,
						themeMode: theme.palette.mode,
					} )}`}>
					Download PDF
				</EmailButton>
			)}
		</Fragment>
	);
}
